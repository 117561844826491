import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import LastUpdated from "../components/LastUpdated/LastUpdated"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 className={`uppercase`}>Page not found</h1>

    <div className={`text-center sm:mt-8`}>
      <p className={`text-4xl font-black leading-tight`} style={{fontSize: '10rem'}}>
        <span className={`text-messi`}>4</span>0<span className={`text-ronaldo`}>4</span>
      </p>
      <p>Hmm, try the <Link className={`underline text-highlight`} to="/">Home</Link> page</p>
    </div>

    <LastUpdated type="Stats" />
    
  </Layout>
)

export default NotFoundPage
